
const $ = require('jquery');

global.Tether = require('tether');
global.$ = global.jQuery = $;

import '../scss/app.scss';

require('bootstrap');
require('tooltip.js');
require('select2');
import InterexFilter from './interex-filter.js';
import InterexList from './interex-list.js';

global.interexFilter = new InterexFilter();
let interexList = new InterexList(".input-filter-interex");

function updateCountries(reloadData){

    $('html,body').scrollTop(0);

    if (reloadData === undefined) {reloadData = true;}

    const data = $("#select-filter-country").select2('data');
    if (data === undefined) {
        return;
    }

    $('#filter-country').empty();
    for (const i in data) {
        const countryText = data[i].text;
        const countryCode = data[i].id;

        let li = $("<li class='interex-item cta cta-fliter cta-filter-fill text-ellipsis' data-country-code='"+countryCode+"'  data-country-label='"+countryText+"'><div class='cta-label_bloc'><span class='cta-label active'>"+countryText+"</span> <i class='icon-filter icon-active'></i></div></li>");

        li.click(function() {
            const countryCode = $(this).data('country-code');
            $('#select-filter-country option[value="'+countryCode+'"]').prop('selected', false);
            $("#select-filter-country").trigger('change.select2');
            updateCountries(true);
        });

        $('#filter-country').append(li);
    }
    $('.country-count').text(data.length);

    if (data.length === 0 && userLogged === false) {
        var formSelector = $('form[name="user_preferences"]');
        if (formSelector.length > 0) {
            //to remove?
            // formSelector.submit();
            // return;
        }
    }

    if (reloadData) {
        $(document).trigger('reload-resources');
    }
}

function updateInterex(reloadData){

    $('html,body').scrollTop(0);

    if (reloadData === undefined) {reloadData = true;}

    const data = $("#select-filter-interex").select2('data');
    if (data === undefined) {
        return;
    }


    $('#filter-interex').empty();
    for (const i in data) {
        const interexText = data[i].text;
        const interexCode = data[i].id;


        let li = $("<li class='interex-item cta cta-fliter cta-filter-fill text-ellipsis' data-interex-code='"+interexCode+"'><div class='cta-label_bloc'><span class='cta-label active'>"+interexText+"</span> <i class='icon-filter icon-active'></i></div></li>");

        li.click(function() {
            const interexCode = $(this).data('interex-code');
            $('#select-filter-interex option[value="'+interexCode+'"]').prop('selected', false);
            $("#select-filter-interex").trigger('change.select2');
            updateInterex(true);
        });

        $('#filter-interex').append(li);
    }
    $('.interex-count').text(data.length);

    if (data.length === 0 && userLogged === false) {
        var formSelector = $('form[name="user_preferences"]');
        if (formSelector.length > 0) {
            //to remove?
            // formSelector.submit();
            // return;
        }
    }

    if (reloadData) {
        $(document).trigger('reload-resources');
    }
}

function initSelect2() {
    let maxCountrySelection = null;
    if ($('#max-selection-countries').length > 0) {
        maxCountrySelection = $('#max-selection-countries').data('value');
        if (maxCountrySelection === 0) {
            maxCountrySelection = null;
        }
    }

    $("#select-country").select2({
        placeholder: selectCountryPlaceholder, //placeholder
        maximumSelectionLength: maxCountrySelection,
        language: {
            maximumSelected: function () {
                return selectCountryMaximumSelected;
            },
            noResults: function (params) {
                return selectNoResults;
            }
        }
    });
    $("#select-filter-country").select2({
        placeholder: selectCountryPlaceholder, //placeholder
        maximumSelectionLength: maxCountrySelection,
        language: {
            maximumSelected: function () {
                return selectCountryMaximumSelected;
            },
            noResults: function (params) {
                return selectNoResults;
            }
        }
    });

    $("#select-filter-country").change(function(){
        var ele = $(this);
        if(ele.val().length==maxCountrySelection)
        {
            ele.select2('close');
        }
    });

    $("#select-filter-interex").select2({
        placeholder: selectInterexPlaceholder, //placeholder
        closeOnSelect: false,
        maximumSelectionLength: maxCountrySelection,
        language: {
            maximumSelected: function () {
                return selectCountryMaximumSelected;
            },
            noResults: function (params) {
                return selectNoResults;
            }
        }
    });

    $("#select-filter-interex").change(function(){
        var ele = $(this);
        if(ele.val().length==maxCountrySelection)
        {
            ele.select2('close');
        }
    });

    let optgroupStateInterex = {};
    let optgroupStateCountry = {};

    $("body").on('click', '.select2-container--open .select2-results__group', function() {
        $(this).toggleClass( "closed");
        $(this).siblings().toggle();
        let id = $(this).closest('.select2-results__options').attr('id');
        let index = $('.select2-results__group').index(this);

        if(id == 'select2-select-filter-interex-results'){
            optgroupStateInterex[id][index] = !optgroupStateInterex[id][index];
        }
    })

    let interexInit = false;

    $('#select-filter-interex').on('select2:open', function() {


        $('.select2-dropdown--below').css('opacity', 0);
        setTimeout(() => {
        let groups = $('.select2-container--open .select2-results__group');
        let id = $('.select2-results__options').attr('id');
        if (!optgroupStateInterex[id]) {
            optgroupStateInterex[id] = {};
        }
        $.each(groups, (index, v) => {
            optgroupStateInterex[id][index] = optgroupStateInterex[id][index] || false;
            optgroupStateInterex[id][index] ? $(v).siblings().show() : $(v).siblings().hide();
            optgroupStateInterex[id][index] ? $(v).removeClass('closed') : $(v).addClass('closed');
    })

        $('.select2-dropdown--below').css('opacity', 1);
    }, 0);
    })

}

$(document).ready(function() {
    initSelect2();
    //Sync country list and pre-selected country in selectbox
    updateCountries(false);
    updateInterex(false);

    $("#select-filter-country").on('change', function(event) {
        updateCountries(true);
    });

    $("#select-filter-interex").on('change', function(event) {
        updateInterex(true);
    });

    // interexFilter.bindList();
    // interexList.bindInput();

    var showChar = 60;  // How many characters are shown by default
    var ellipsestext = "...";
    var moretext = "";
    var lesstext = "";

    $(".seemorelink").click(function(){
        if($(this).hasClass("less")) {
            $(this).removeClass("less");
            $(this).html(moretext);
        } else {
            $(this).addClass("less");
            $(this).html(lesstext);
        }
        $(this).parent().prev().toggle();
        $(this).prev().toggle();
        return false;
    });

    $('*[data-target="#modalLogin"]').click(function(){
        let cta = $(this).data('cta');
        if("show-signin" == cta){
            $('#modalLogin a#show-signin').trigger('click');
        };
        if("show-register" == cta){
            $('#modalLogin button#show-register').trigger('click');
        };
    });

});

$(function () {
    $("body").tooltip({ selector: '[data-toggle=tooltip]' });
})
