export default class InterexList {
    constructor(inputSelector) {
        this.inputSelector = inputSelector;
    }

    bindInput() {
        const self = this;

        $("#modal-filter-interex").on('show.bs.modal', function(){
            self.resetModal();
        });


        $(this.inputSelector).on('input', function () {
            self.showSearchInInterexSelectList($(self.inputSelector).val());
        });

        $('.panel-collapse').on('show.bs.collapse', function () {
            $(this).parent().find('span.add-interex:first').addClass('expended');
        });

        $('.panel-collapse').on('hide.bs.collapse', function () {
            $(this).parent().find('span.add-interex:first').removeClass('expended');
        });



    }

    //when search with key word we hide interex by category and only display INTEREX that match search
    showSearchInInterexSelectList(keyword) {
        if (keyword.length === 0) {
            $('#accordionMenu').show();
            $('#searchCategory').hide();
            $('span#modale-interex-no-result').hide();
            return;
        }

        $('#accordionMenu').hide();

        let interex = $('#searchCategory').find('[data-interex-label]');
        let interexFound = false;
        $('span#modale-interex-no-result').hide();

        interex.each(function () {
            const label = $(this).data('interex-label');
            const code = $(this).data('interex-code');

            if (label !== undefined && label.toLowerCase().search(keyword.toLowerCase()) !== -1) {
                $(this).show();
                interexFound = true;
            } else {
              $(this).hide();
            }
        });

        //diplay wall.interex.modale.no-result if not interex where found
        $('#searchCategory').show();
        if(interexFound == false){
            $('span#modale-interex-no-result').show();
        }
    }

    resetModal(){
        $('input.input-filter-interex').val('');
        $('span#modale-interex-max-selected').hide();
        $('#accordionMenu').show();
        $('#searchCategory').hide();

        return;
    }
}
