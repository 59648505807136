export default class InterexFilter {
    constructor() {
        this.interexList = [];
        this.maxInterexSelection = null;
    }

    updateFilter(code, label) {

        $('span#modale-interex-max-selected').hide();

        if ($('#max-selection-interex').length > 0) {
            this.maxInterexSelection = $('#max-selection-interex').data('value');
            if (this.maxInterexSelection === 0) {
                this.maxInterexSelection = null;
            }
        }


        $('html,body').scrollTop(0);

        let codeRemoved = false;

        for (let i in this.interexList) {
            let interex = this.interexList[i];
            if (interex.code == code) {
                this.interexList.splice(i, 1);
                i -= 1;
                codeRemoved = true;
            }
        }

        //this.countInterex = $('span.add-interex.selected').length;

        if(this.maxInterexSelection > this.interexList.length)
        {
            if (codeRemoved === false) {
                this.interexList.push({code: code, label: label});
            }
        }else{
            $('span#modale-interex-max-selected').show();
        }

        $(document).trigger('reload-resources');
        $(document).trigger('update-metric');

        this.updateFilterListHtml();
        this.updateSelectedInterex();
        //this.disableAddInterex();

    }

    bindList() {
        let self = this;

        //Filter list
        $('.add-interex').click(function() {
            let target = $(this).parent().find('a');
            self.updateFilter(target.data('interex-code'), target.data('interex-label'));
        });
        
        const preselectedList = $('#user_preferences_interex_interex option:selected');

        preselectedList.each(function() {
            self.updateFilter($(this).attr('value'), $(this).html());
        });
    }

    updateFilterListHtml() {
        const self = this;

        $('#filter-interex').empty();
        $('#user_preferences_interex_interex option').prop('selected', false);

        for (const i in this.interexList) {
            let interex = this.interexList[i];

            let label = interex.label;
            if (label.length > 24) {
                label = label.substr(0, 24) + "...";
            }

            let li = $("<li class='interex-item cta cta-fliter cta-filter-fill text-ellipsis' data-interex-code='"+interex.code+"'><div class='cta-label_bloc'><span class='cta-label active'>"+label+"</span> <i class='icon-filter icon-active'></i></div></li>");

            li.click(function() {
                self.updateFilter($(this).data('interex-code'));
            });

            $('#filter-interex').append(li);

            $('#user_preferences_interex_interex option[value="'+interex.code+'"]').prop('selected', true);
        }

        $('.interex-count').text(this.interexList.length);

    }

    getFilterInterexCode() {
        let codes = [];

        for (const i in this.interexList) {
            let interex = this.interexList[i];

            codes.push(interex.code);
        }

        return codes;
    }

    updateSelectedInterex() {

        $('#modal-filter-interex .selected').removeClass('selected');
        for (const i in this.interexList) {
            let interex = this.interexList[i];

            let code = interex.code;

            $('#modal-filter-interex').find('[data-interex-code="'+code+'"]').addClass('selected');
        }
    }
}
